@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --e-global-color-primary: #00EE00;
    --e-global-color-secondary: #000000;
    --e-global-color-text: #666666;
    --e-global-color-accent: #61CE70;
    --e-global-color-32b3a35: #FFFFFF;
    --btn-color: #00EE00;
    --primary-color: #00EE00;
    --secondary-color: #000
}

html {
    padding: 0px;
    margin: 0px;
}

ul {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

body {
    margin: 0;
    font-family: "Poppins", system-ui;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* header */

.header nav.nav {
    display: block;
}

.header nav ul>li>span.active:hover,
.header nav ul>li>a.active:hover {}

.header nav ul>li>span:hover,
.header nav ul>li>a:focus,
.header nav ul>li>a:hover {
    color: var(--btn-color);
}

.header nav ul>li>span:hover:before,
.header nav ul>li>a:hover:before {
    left: 0;
    right: auto;
    width: 100%;
}

.header nav ul>li>span::before,
.header nav ul>li>a::before {
    position: absolute;
    content: '';
    bottom: 0px;
    right: 0;
    height: 2px;
    width: 0%;
    transition: all .35s cubic-bezier(.645, .045, .355, 1);
    background-color: var(--primary-color);
}

.header nav ul {
    list-style: none;
    padding-left: 0px;
}

.inner-menu {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.header nav ul li.nav-item .btn::before {
    display: none;
}

.header nav ul li.nav-item .btn {
    line-height: normal;
    padding: 5px 6px 5px 15px;
    color: #fff;
    margin-left: 40px;
}

.header nav ul li.nav-item .btn i {
    margin-left: 20px;
}

ul.nav-list {
    display: flex;
    align-items: center;
}

.header nav ul li:not(:last-child) {
    border-right: 1px solid #D8D8D8;
}

.header nav ul li {
    background: #FFFFFF;
}

.header {
    position: relative;
    width: 100%;
    z-index: 1000;
    background-color: #fff;
    position: fixed;
    top: 0;
    border-bottom: 1px solid #D8D8D8;
    left: 0;
    right: 0;
}

.header.pxl-header-fixed {
    background-color: #fff;
    box-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    transition: .6s;
}

.header nav ul li span,
.header nav ul li a {
    display: inline-block;
    position: relative;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
    padding: 0px 0px;
    padding: 0px 30px;
    line-height: 93px;
}


.header .logo img {
    width: auto;
    height: 75px;
}

#pxl-header-elementor .pxl-header-elementor-sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    top: 0;
    transform: translateY(-120%);
    transition: .6s cubic-bezier(.24, .74, .58, 1);
    visibility: hidden;
}

footer .btn i,
.about-section .btn i {
    background-color: #000000;
}

.btn:hover i,
.btn:hover i {
    background-color: #000;
}

.margindiv-header {
    padding-top: 93px;
}

.btn i {
    color: #FFFFFF;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: var(--btn-color);
    margin-left: 30px;
}

footer .btn,
.about-section .btn {
    background-color: var(--btn-color);
}

footer .btn:hover,
.about-section .btn:hover {
    color: #000;
    background-color: #fff;
}

.btn {
    color: #fff;
    padding: 8px 6px 8px 30px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    position: relative;
    cursor: pointer;
    border: none;
    height: auto;
    display: inline-flex;
    align-items: center;
    text-align: center;
    background-color: #000000;
    justify-content: center;
    overflow: hidden;
    z-index: 1;
    border-radius: 0px;
}

.btn i:before {
    display: block;
    transform: rotate(-45deg);
    transition: all .15s cubic-bezier(.645, .045, .355, 1);
}

.btn:hover i:before {
    -webkit-transform: rotate(0deg);
    -khtml-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
}

.btn:hover {
    color: #fff;
    background-color: var(--btn-color);
}

.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
    color: #000;
    background-color: #FFFFFF;
}

p {
    font-size: 15px;
    line-height: 26px;
    font-weight: 400;
    color: #000;
}

/* footer start */
footer.footer {
    background-color: #000;
    background-position: top right;
    background-repeat: no-repeat;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 60px 0px 0px 0px;
}

.ready-to-work p {
    color: #CDCDCD;
}

.ready-to-work h3 {
    margin-bottom: 15px;
    color: var(--e-global-color-32b3a35)!important;
    -webkit-text-stroke-color: var(--e-global-color-32b3a35);
    font-family: "Poppins", system-ui!important;
    font-size: 40px;
    font-weight: 700;
    line-height: 55px;
}

.ready-to-work {
    text-align: left;
}

.pxl-el-divider {
    background-color: #3E4042;
    height: 1px;
    width: 100%;
    height: 1px;
    margin: auto;
    position: relative;
    transition: all .5s cubic-bezier(.645, .045, .355, 1);
    text-align: center;
}

.pxl-el-divider:before {
    position: absolute;
    content: '';
    top: -4.5px;
    left: 0;
    width: 9px;
    height: 9px;
    background-color: var(--primary-color);
    /* transform: translatey(-50%) scale(0); */
    transition-delay: 500ms !important;
    transition: all .4s cubic-bezier(.645, .045, .355, 1);
}

.pxl-el-divider:after {
    position: absolute;
    content: '';
    top: -4.5px;
    right: 0;
    width: 9px;
    height: 9px;
    background-color: var(--primary-color);
    transition-delay: 500ms !important;
    transition: all .4s cubic-bezier(.645, .045, .355, 1);
}

.logofooters img {
    max-height: 100px;
    width: auto;
    vertical-align: middle;
}

.menu-footer {
    margin: 50px 0px 0px 0px;
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: #3E4042;
    transition: background .3s, border .3s, border-radius .3s, box-shadow .3s, transform var(--e-transform-transition-duration, .4s);
}

.menu-footer ul li a:hover {
    color: var(--primary-color);
}

.menu-footer ul li a {
    color: #FFFFFF;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    position: relative;
    padding: 20px 0px;
}

.menu-footer ul li a.active:before,
.menu-footer ul li a:hover:before {
    left: 0;
    right: auto;
    width: 100%;
}

.menu-footer ul li a.active::before,
.menu-footer ul li a::before {
    position: absolute;
    content: '';
    bottom: -1px;
    top: auto;
    right: 0;
    height: 4px;
    width: 0%;
    transition: all .35s cubic-bezier(.645, .045, .355, 1);
    background-color: var(--primary-color);
}

.menu-footer ul {
    padding: 0px;
}

.menu-footer ul li {
    display: inline-block;
    vertical-align: middle;
    margin: 0px 40px 0px 0px;
}

.social-media-links {
    margin-top: 40px;
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.social-media-links a {
    color: #58595C;
    background-color: #2E3033;
    width: 45px;
    height: 45px;
    line-height: 45px;
    border-radius: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    display: inline-block;
    text-align: center;
}

.social-media-links a:hover {
    color: #FFFFFF;
    background-color: var(--e-global-color-primary);
}

.footer-widgets h3 {
    color: var(--e-global-color-32b3a35);
    -webkit-text-stroke-color: var(--e-global-color-32b3a35);
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 20px;
}

.pxl-item--meta a {
    color: #CDCDCD;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
}

.pxl-item--meta a i {
    color: var(--e-global-color-primary);
    text-fill-color: var(--e-global-color-primary);
    -webkit-text-fill-color: var(--e-global-color-primary);
    background-image: var(--e-global-color-primary);
    font-size: 14px;
}

.pxl-item--meta a {
    transition: background .3s, border .3s, border-radius .3s, box-shadow .3s, transform var(--e-transform-transition-duration, .4s);
    display: flex;
    column-gap: 15px;
    align-items: baseline;
}

.pxl-item--meta:not(:last-child) {
    margin-bottom: 15px;
}

.pxl-item--meta:hover i, .pxl-item--meta:hover svg, .pxl-item--meta:hover img {
    animation-name: scale;
    animation-duration: .7s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes scale {
    25% {
        transform: scale(1.1)
    }

    75% {
        transform: scale(.9)
    }
}

.footer-widgets p, .copyright p, .privacy-links a {
    color: #CDCDCD;
    font-weight: 400;
    font-size: 14px;
}

.copyright p, .privacy-links p {
    margin-bottom: 0px;
}

.input-filled input {
    background-color: #f5f6f6;
    border: none;
    color: #646464;
    line-height: 1.73;
    font-weight: 400;
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    box-shadow: none;
    width: 100%;
    outline: none;
    border-radius: 0px;
    font-family: inherit;
    border-radius: 0;
    padding: 0 17px;
    color: #666;
    height: 55px;
    font-size: 16px;
}

.input-filled {
    position: relative
}

.input-filled i {
    transform: rotate(-45deg);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.input-filled button:hover {
    background-color: var(--primary-color);
}

.input-filled button:hover i {
    transform: rotate(0);
}

.input-filled button {
    position: absolute;
    width: 45px;
    height: 45px;
    text-align: center;
    background-color: #000000;
    top: 5px;
    color: #fff;
    border: 0px;
    right: 5px;
}

.copyright p a {
    color: var(--btn-color);
}

.copyright a:hover,
.privacy-links a:hover {
    color: var(--btn-color);
}

.privacy-links {
    justify-content: end;
    display: flex;
    gap: 15px;
}

.copyright {
    padding: 15px 0px;
}

.pxl-item--logo:hover img {
    filter: grayscale(0);
    opacity: 1;
    transform: scale(.85);
}

.pxl-item--logo img {
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    filter: grayscale(1);
    opacity: .5;
}

.partners-logo {
    padding: 50px 0px;
}

/* .section-title span {
    position: relative;
    
} */

/* .section-title span:before {
    position: absolute;
    content: '';
    top: 50%;
    left: 0;
    transform: translatey(-50%);
    width: 8px;
    height: 8px;
    background-color: var(--primary-color);
} */
section.testimonial-section .container{
    position: relative;
}
span.text-success, span.text-danger {
    margin-top: 10px;
    display: block;
}
.section-title h2, .sitemapaddpage h2 {
    font-size: 35px;
    font-weight: 600;
    line-height: 50px;
    margin-bottom: 0px;
    margin-top: 5px;
}

.section-title img {
    width: 40px;
    display: block;
    margin-right: -40px;
    float: right;
}

.testimonial-section .section-title {
    width: fit-content;
    margin-bottom: 25px;
}

.pxl-item--desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    position: relative;
    padding-bottom: 39px;
    border-bottom: 1px solid #d7d7d7;
}

.pxl-item--desc:before {
    position: absolute;
    content: '';
    right: 0;
    bottom: -4px;
    width: 8px;
    height: 8px;
    background-color: var(--primary-color);
}

.pxl-item--desc .pxl-item--avatar:before {
    position: absolute;
    content: '';
    left: -4px;
    bottom: -4px;
    background-color: var(--primary-color);
    width: calc(100% - 10px);
    height: calc(100% - 10px);
}

.pxl-item--avatar {
    width: 82px;
    height: 82px;
    position: relative;
}

.pxl-item--inner .content-bottom {
    margin-top: 40px;
    display: flex;
    align-items: end;
    justify-content: space-between;
}

.pxl-item--inner .pxl-item--holder .pxl-item--avatar:before {
    position: absolute;
    content: '';
    left: 0px;
    bottom: 0px;
    background-color: var(--primary-color);
    width: calc(100% - 10px);
    z-index: 1;
    height: calc(100% - 10px);
}

.pxl-item--inner h3 {
    font-size: 18px;
    font-weight: 700;
    color: var(--secondary-color);
    line-height: 25px;
    margin-bottom: 3px;
}

.pxl-item--inner p {
    margin-top: 3px;
    font-size: 14px;
    color: #666;
    margin-bottom: 0px;
}

.pxl-item--inner .pxl-item--holder {
    column-gap: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pxl-item--inner .btn-more a {
    font-size: 14px;
    color: #000;
    font-weight: 600;
    column-gap: 10px;
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 1.28px;
    transition: 200ms all linear 0ms !important;
}

.pxl-item--inner .btn-more a i {
    transform: rotate(-45deg);
    transition: 200ms all linear 0ms !important;
}

.pxl-item--inner .btn-more a:hover i {
    transform: rotate(0);
}

.pxl-item--inner .btn-more a:hover {
    color: var(--primary-color);
}

.pxl-item--inner .pxl-item--holder .pxl-item--avatar img {
    filter: grayscale(1);
    position: relative;
    padding-left: 4px;
    padding-bottom: 4px;
    z-index: 2;
}

section.testimonial-section button.owl-next, section.testimonial-section button.owl-prev {
    background-color: #000 !important;
    width: 45px;
    height: 45px;
    position: relative;
    transition: all 300ms linear 0ms;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px !important;
    color: #fff !important;
    font-size: 18px;
    z-index: 1;
}

section.testimonial-section .owl-carousel.owl-theme {
    /* padding-right: 120px; */
    position: unset;
}

section.testimonial-section .owl-theme .owl-nav {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0px;
    transform: translate(0px, -50%);
    top: 50%;
}

.testimonial-section {
    background-position: left;
    background-repeat: no-repeat;
    padding: 70px 0px;
    background-size: contain;
    margin-top: 20px;
}

section.about-section img.rightimg-about {
    max-width: auto;
    top: -259px;
    right: 195px;
    max-height: 390px;
    width: auto;
    position: absolute;
}

section.about-section img.lefttimg-about {
    max-width: auto;
    bottom: -259px;
    left: 0px;
    max-height: 390px;
    width: auto;
    position: absolute;
}

section.about-section {
    overflow: hidden;
    background-color: #000;
    position: relative;
    padding: 100px 0px 130px 0px;
}

.about-owlslider h3 {
    font-size: 16px;
    color: #cdcdcd;
    font-weight: 400;
    margin-bottom: 0px;
}

.about-owlslider p {
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    color: #fff;
}

.about-owlslider {
    padding: 25px 25px 25px;
}

.about-owlslider h3 {
    font-size: 16px;
    color: #cdcdcd;
    font-weight: 400;
}

.orl-casosel-inner {
    background-position: bottom;
    background-color: #000;
    border-left: 5px solid var(--primary-color);
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
}


section.about-quesolution button.owl-next, section.about-quesolution button.owl-prev {
    background-color: #000 !important;
    width: 30px;
    height: 25px;
    position: relative;
    transition: all 300ms linear 0ms;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px !important;
    color: #fff !important;
    font-size: 22px !important;
    z-index: 1;
}

section.about-quesolution .owl-theme .owl-nav {
    display: flex;
    background-color: #000;

    /* flex-direction: column; */
    position: absolute;
    right: 20px;
    transform: translate(0px, -50%);
    bottom: 0px;
}

.about-quesolution {
    padding: 90px 0px;
}

.welcomesolution {
    padding: 8px 25px;
    display: inline-block;
    font-size: 14px;
    line-height: 26px;
    text-transform: uppercase;
    background-color: var(--primary-color);
    font-weight: 500;
    color: #000;
    margin-bottom: 20px;
}

section.hero-sec h1 span {
    color: var(--btn-color);
}

section.hero-sec h1 {
    font-size: 40px;
    font-weight: bold;
    line-height: 1.18182;
    color: #fff;
    margin-bottom: 13px;
}

section.hero-sec ul li {
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-transform: capitalize;

    color: #FFFFFF;
    display: flex;
    align-items: center;

}

section.hero-sec ul {
    display: flex;
    gap: 30px;
    padding-left: 0px;
    list-style: none;
    align-items: center;
}

section.hero-sec ul {
    margin-top: 25px;
    margin-bottom: 25px;
}

section.hero-sec ul li img {
    margin-right: 10px;
    height: 25px;
    width: auto!important;
}


section.hero-sec .item-hero-inner{
    display: flex;
    align-items: center;
    padding: 150px 0px 150px 0px;
    height: 100%;
    animation: zoomIn 2s ease-out forwards;
}
section.hero-sec .item {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
/* Keyframes for zoom-in effect */
@keyframes zoomIn {
    0% {
      background-size: 120%; /* Start with image scaled up */
      opacity: 0; /* Optional: start with opacity 0 for a fade effect */
    }
    100% {
      background-size: 100%; /* End with image at its original size */
      opacity: 1; /* Optional: end with opacity 1 */
    }
  }
section.hero-sec {

}

.whtwe-box:before {
    position: absolute;
    content: '';
    top: -1px;
    left: 50%;
    transform: translatex(-50%);
    background-color: var(--primary-color);
    transition: all .35s cubic-bezier(.645, .045, .355, 1);
    height: 4px;
    width: 0;
}

.whtwe-box:hover {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
}

.whtwe-box:hover h3,
.whtwe-box:hover p {
    color: #fff;
}

.whtwe-box p {
    font-size: 14px;
    line-height: 26px;
}

.whtwe-box h3 {
    margin: 0 0 15px;
    transition: all .35s cubic-bezier(.645, .045, .355, 1);
    font-size: 20px;
    font-weight: bold;
    color: var(--secondary-color);
}

.whtwe-box:hover:before {
    width: calc(100% + 2px);
}

.whtwe-box:hover {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
}

.whtwe-box img {
    height: 60px;
    margin-top: 4px;
    margin-bottom: 19px;
}

.whtwe-box {
    padding: 25px 15px 15px;
    height: 100%;
    text-align: center;
    position: relative;
    border: 1px solid #d7d7d7;
    background: #fff;
    transition: all .35s cubic-bezier(.645, .045, .355, 1);
}

.whatweoffer {
    overflow: hidden;
    position: relative;
    padding: 60px 0px 0;
    margin-bottom: 80px;
}

.whatweoffer::before {
    position: absolute;
    top: 0px;
    height: 75%;
    background-image: url('../src/assets/img/bg-h2.png');
    content: "";
    width: 100%;
}

.aboutus-whtaweoffer::before {
    display: none;
}

.whatweoffer .container {
    position: relative;
    z-index: 2;
}

button.tab-button:before {
    position: absolute;
    content: '';
    right: 0;
    bottom: 0;
    height: 3px;
    background-color: var(--primary-color);
    width: 0%;
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

section.about-section .tabs {
    display: flex;
    align-items: center;
    column-gap: 30px;
}

section.about-section button.tab-button {
    font-size: 16px;
    font-weight: 500;
    padding: 0px;
    color: #999;
    position: relative;
    padding-bottom: 18px;
    background-color: transparent;
    border: 0px;
}

button.tab-button.active:before {
    width: 100%;
    right: auto;
    left: 0;
}

section.about-section button.tab-button.active {
    color: #fff;
}

section.about-section h2 {
    color: #fff;
    margin-bottom: 15px;
}
section.about-section img{
    animation: zoomIn 2s ease-out forwards;
}
.innerabout-tab h2,
.innerabout-tab p {
    color: #fff;
}

.innerabout-tab, .bg-about-content {
    max-width: 630px;
}

section.about-section .img-fluid {
    width: 100%;
}

/* about us page */
.breadrum-tittle h1 {
    font-weight: 700;
    font-size: 42px;
    line-height: 89px;
    color: #FFFFFF;
    border-bottom: 1px solid #D9D9D9;

}

.breadrum-tittle .container {
    position: relative;
    z-index: 1;
}

.breadrum-tittle {
    position: relative;
    padding: 150px 0px 30px;
    background-size: cover;
    background-repeat: no-repeat;
}

.breadrum-tittle::after {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    content: "";
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));

}

.breadrum-tittle ul {
    display: flex;
    padding: 0px;
    list-style: none;
    gap: 10px;
    margin-top: 20px;
}

.breadrum-tittle ul li:last-child {
    margin-left: 5px;
}

.breadrum-tittle ul li {
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
}

.breadrum-tittle ul li i {
    transform: rotate(45deg);
    color: var(--btn-color);
}

.breadrum-tittle ul li a:hover {
    color: var(--btn-color);
}

.breadrum-tittle ul li a {
    color: #fff;
}

.bg-about-inner {
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-about-content-outer::before {
    position: absolute;
    top: -40px;
    background: #F3F5FA;
    content: "";
    width: 100%;
    height: 40px;
}

.bg-about-content-outer::after {
    position: absolute;
    bottom: -40px;
    background: #F3F5FA;
    content: "";
    width: 100%;
    height: 40px;
}

.bg-about-content {
    padding: 50px 40px;
}

.bg-about-content-outer {
    height: 100%;
    position: relative;
    background: #F3F5FA;
}

section.about-innerpage {
    padding: 90px 0px;
}

.suppro-addres p {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    color: #666666;
    margin-bottom: 0px;
}

.suppro-addres h3 {
    font-size: 30px;
    font-weight: 600;
    color: #000;
    margin-top: 10px;
}

.suppro-addres h3 a {
    color: #000;
}

.suppro-addres p a {
    color: #000;
}

.suppro-addres h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 35px;
    color: #000;
    margin-bottom: 0px;
}

.form-check input {
    float: none !important;
    margin-left: 0px !important;
}

.form-check input, .form-check-label:before {
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    margin: 0;
    background-color: #f5f6f6;
    cursor: pointer;
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.form-check {
    position: relative;
    padding-left: 42px;
    display: flex;
    font-size: 14px;
    align-self: center;
}

.form-check-label:after {
    content: "\f00c";
    font-family: var(--fa-style-family, "Font Awesome 6 Free");
    font-weight: var(--fa-style, 900);
    position: absolute;
    left: 10px;
    font-size: 13px;
    top: 50%;
    transform: translate(0, -50%);
    color: var(--secondary-color);
    z-index: 2;
    opacity: 0;
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

.form-check input:checked+.form-check-label:after {
    opacity: 1;
}

.form-check input {
    opacity: 0;
    z-index: 3;
}

.conatctuseform {
    border-top: 5px solid var(--primary-color);
    background-color: #fff;
    padding: 20px 35px 30px;
    border-bottom: 1px solid #CECECE;
    border-left: 1px solid #CECECE;
    border-right: 1px solid #CECECE;
    border-radius: 10px;
}

.conatctuseform .form-control,
.conatctuseform .form-select {
    border-radius: 0;
    padding: 0 12px!important;
    color: #666;
    height: 50px;
    font-size: 14px;
    background-color: #fff;
    padding-left: 22px;
    border: 1px solid #d7d7d7;
}

.conatctuseform textarea.form-control {
    height: 120px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 12px!important;
    border-radius: 0;
    background-color: #fff;
    color: #666;
    font-size: 14px;
    border: 1px solid #d7d7d7;
}

.conatctuseform select:focus,
.conatctuseform textarea:focus,
.conatctuseform input:focus {
    box-shadow: none;
    border-color: #00EE00;
}

.contact-quesolution {
    padding: 60px 0px 30px;
}

.product-list {
    padding: 50px 0px;
}

.product-box {
    background: #FFFFFF;
    border: 1px solid #D7D7D7;
    border-radius: 20px;
    padding: 10px 10px;
    height: 100%;
    text-align: center;

}

.title-product h2 {
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 16px;
    line-height: 33px;
    color: #000000;
    margin-bottom: 0px;
}

.title-product {
    padding: 10px 15px;
    background: #F3F3F3;
    border-radius: 20px;
    position: relative;
    z-index: 1;
    margin-top: 10px;

}

.product-box:hover .title-product {
    background: #000;

}

.product-box:hover .title-product h2 {
    color: #fff;

}

.product-box img {
    width: auto!important;
    height: 250px;
    padding: 20px;
    margin: auto;
    object-fit: cover;
    margin-bottom: 0 !important;
    transition: transform .3s cubic-bezier(.44, .17, 1, 1);
}

.product-box:hover img {
    transform: scale(1.05);
}

ul.pagination.justify-content-center li.page-item.active button.page-link {
    background-color: #555555;
    border-color: #555555;
    color: #fff;
}

ul.pagination.justify-content-center li.page-item button.page-link {
    width: 50px;
    height: 50px;
    border-radius: 0px;
    line-height: 30px;
    font-size: 22px;
    color: #000;
}

ul.pagination.justify-content-center {
    gap: 20px;
    justify-content: start !important;
}

.page-link:focus {
    box-shadow: none;
}
.slider-box-product{
    background: #FFFFFF;
    border: 1px solid #D7D7D7;
    border-radius: 20px;
    padding: 10px 30px 50px;
}
.slider-box-product  img{
    width: 100%!important;
    margin: auto;
}
.slider-box-product button.owl-next, .slider-box-product button.owl-prev {
    background-color: #000 !important;
    width: 50px;
    height: 50px;
    position: relative;
    transition: all 300ms linear 0ms;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px !important;
    color: #fff !important;
    font-size: 18px;
    z-index: 1;
}
.slider-box-product img{
    text-align: center;
}

.slider-box-product .owl-theme .owl-nav {
    display: flex;
    position: absolute;
    right: -15px;
    bottom: -35px;
}

.product-details-content h2{
font-weight: 600;
font-size: 20px;
line-height: 33px;
color: #000000;
margin-bottom: 15px;
}
.product-details-content ul {
    padding-left: 20px;
}
.product-details-content ul li{
font-weight: 400;
font-size: 16px;
line-height: 29px;
color: #828282;
}
.staticpage ul {
    margin-bottom: 20px;
    padding-left: 20px;
}
.staticpage {
    padding-top: 40px;
}
.staticpage ul li{
font-weight: 400;
font-size: 14px;
line-height: 29px;
color: #000;
}
section.product-detailsinner .description{
    width: fit-content;
    padding: 5px 15px;
font-weight: 500;
font-size: 16px;
line-height: 24px;
text-align: center;
text-transform: capitalize;
color: #121315;
border-radius: 100px;
background-color: var(--btn-color);
margin-bottom: 20px;

}
section.product-detailsinner {
    padding: 50px 0px;
}
.related-products {
    padding: 40px 0px;
}
.about-quesolution {
overflow: hidden;
}


/* OffCanvas.css */

.pxl-header-menu {
    position: fixed;
    height: 100vh;
    width: 300px;
    top: 0;
    left: 0;
    bottom: 0;
    margin: 0;
    overflow: hidden;
    z-index: 1000;
    -webkit-transition: all .25s cubic-bezier(.645,.045,.355,1);
    -khtml-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -moz-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -ms-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645,.045,.355,1);
    -webkit-transform: translateX(-100%);
    -khtml-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
    visibility: hidden;
    -webkit-box-shadow: 0 0 15px rgba(,,, .08);
    -khtml-box-shadow: 0 0 15px rgba(,,, .08);
    -moz-box-shadow: 0 0 15px rgba(,,, .08);
    -ms-box-shadow: 0 0 15px rgba(,,, .08);
    -o-box-shadow: 0 0 15px rgba(,,, .08);
    box-shadow: 0 0 15px rgba(,,, .08);
    background-color: #fff;
    overflow-y: scroll;
}
  
  .pxl-header-menu.show {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateX(0%);
    -khtml-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
}
  
  .pxl-header-menu-scroll {
    padding: 20px;
  }
  
  .pxl-header-menu-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  
  .pxl-nav-mobile-button {
    cursor: pointer;
  }
  
  .pxl-icon-line {
    display: block;
    height: 3px;
    width: 25px;
    background-color: #000;
    border-radius: 100px;
}
  
  .pxl-menu-close {
    cursor: pointer;
    color: #fff;
    font-size: 24px;
  }
  .pxl-logo-mobile.pxl-hide-xl img{
    width: 140px;
    margin-bottom: 30px;
  }
  .pxl-anchor-divider .pxl-icon-line.pxl-icon-line1 {
    top: 0;
    width: 22px;
    right: 0;
    left: auto;
}
.pxl-anchor-divider .pxl-icon-line {
    height: 3px;
    position: absolute;
    right: 0;
}
.pxl-anchor-divider .pxl-icon-line.pxl-icon-line2 {
    top: 10px;
    width: 30px;
    right: 0;
    left: auto;
}
.pxl-anchor-divider .pxl-icon-line.pxl-icon-line3 {
    left: auto;
    width: 20px;
    right: 0;
    bottom: 3px;
}
.pxl-anchor-divider {
    overflow: hidden;
    width: 36px;
    height: 26px;
    cursor: pointer;
    position: relative;
}
 
  .pxl-close:before {
    -webkit-transform: rotate(45deg);
    -khtml-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.pxl-close:before, .pxl-close:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    transform-origin: 50% 50%;
    -webkit-transition: all .25s cubic-bezier(.645,.045,.355,1);
    -khtml-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -moz-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -ms-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645,.045,.355,1);
    background-color: #1b1b1b;
}
.pxl-close:before {
    -webkit-transform: rotate(45deg);
    -khtml-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}
.pxl-close:after {
    -webkit-transform: rotate(-45deg);
    -khtml-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.pxl-close {
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    background: rgba(255, 255, 255, .7);
    border: 9px solid transparent;
    color: #777;
    width: 36px;
    height: 36px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
}
nav.pxl-header-nav ul{list-style: none;
padding: 0px;}
nav.pxl-header-nav ul li a{
display: block;
line-height: 46px;
color: #121315;
font-size: 15px;
font-weight: 600;
text-align: left;
border-bottom: 1px solid rgba(0, 0, 0, 6%);
}
.pxl-anchor-divider {
    display: none;
}
.modal.show .modal-dialog{
z-index: 9999;
}

.main_header_tittle { position: relative;

    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: .5px;
    text-transform: uppercase;
    padding-left: 20px;
    color: #999999;
}



.main_header_tittle:before{


    position: absolute;
    content: '';
    top: 50%;
    left: 0;
    transform: translatey(-50%);
    width: 8px;
    height: 8px;
    background-color: var(--primary-color);
}

.accordion-button{
    border: 0px!important;
    font-weight: 600;
    font-size: 18px;
    padding-left: 0px;
    padding-right: 0px;
    color: #000;
    opacity: 1;
 }
 .accordion-body{
     padding:15px 0px!important;
 } 
 .accordion-item p{
     margin-bottom: 0px;
 }
 .accordion-item{
     border-bottom: 1px solid #1e2023;
     border-top: 1px solid #1e2023;
     border-color: #d7d7d7;
     border-left: 0px !important;
     border-right: 0px !important;
     border-radius: 0px !important;
     font-size: 14px;
 }
 .accordion-button:not(.collapsed) {
     color: var(--btn-color);
     padding-bottom: 0px;
 }
 .accordion-button:focus, .accordion-button:not(.collapsed){
     box-shadow: none;
     background-color: #fff;
 }
 .faq-section{
     padding-top: 50px;
     padding-bottom: 40px;
 }
 .sitemapaddpage ul li a:hover{
    color: var(--btn-color);
    text-decoration: underline;
 }
 .sitemapaddpage ul li a {
    color: #000;
 }
 .modal .d-md-flex.align-item-center.justify-content-between button.btn{
    width: fit-content;
    margin: 15px auto 0px;
    display: block;
 }
 .modal .d-md-flex.align-item-center.justify-content-between{
    display: block!important;
 }
 