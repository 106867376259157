 /* Hide menu on larger screens */

/* Hide menu on larger screens */
 @media (min-width: 991.98px) {
    .pxl-header-menu {
      display: none;
    }
  }
@media (max-width: 1200.98px) {
        .pxl-menu-close {
            position: absolute;
            top: 0;
            right: 0;
            -webkit-border-radius: 0;
            -khtml-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0;
        }
}
@media (max-width: 1366.98px) {

    .section-title h2, .sitemapaddpage h2 {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 10px;
    }
    .whtwe-box img {
        height: 55px;
    }
    p, .about-owlslider p{
        font-size: 14px;
    }
    .testimonial-section{
        background-size: 19%;
        background-position: bottom left!important;
    }
    .testimonial-section .container {
        position: relative;
    }
}
@media (max-width: 1210.98px) {
    .testimonial-section {
        background-size: 17%;
        background-position: bottom left !important;
    }
  }
@media (max-width: 1200.98px) {
    .container, .container-lg, .container-md, .container-sm {
        max-width: 100%;
    }
    section.hero-sec .item-hero-inner {
        padding: 120px 0px 120px
    }
    section.about-section .tabs{
        gap: 15px;
    }
    section.about-section{
        padding: 100px 0px 80px 0px;
    }
    section.testimonial-section .owl-theme .owl-nav{
        right: 10%;
    }
    .testimonial-section {
        background-position: bottom left;
    }
}
@media (max-width: 1024.98px) {
    section.hero-sec .item-hero-inner {
        padding: 90px 0px 90px;
    }
    .header .logo img {
        width: auto;
        height: 65px;
    }
    .header nav ul li span, .header nav ul li a {
        line-height: 80px;
    }
    .margindiv-header{
        padding-top: 80px;
    }
    .whtwe-box p{
        margin-bottom: 0px;
    }

}
@media (max-width: 991.98px) {
    .header nav ul li:not(:last-child) {
        border-right: 0px;
    }
    .pxl-anchor-divider {
        display: block;
    }
    .header nav ul li span, .header nav ul li a{
        line-height: 50px;
        padding-left: 0px;
        padding-right: 0px;
    }
    .header nav ul li.nav-item .btn{
        margin-left: 0px;
        margin-top: 20px;
        width: auto;display: inline-block;
    }
    .header nav ul li.nav-item .btn i {
        margin-left: 20px;
        line-height: 40px;
        text-align: center;
    }
    .header{
        padding: 10px 0px;
    }
    .about-quesolution, .whatweoffer, section.about-section, .testimonial-section, .partners-logo, section.about-innerpage, section.product-detailsinner {
        padding: 40px 0px;
        margin-bottom: 0px;
    }
    .related-products, .partners-logo{
        padding-top: 0px ;
    }
    section.about-section{
        margin-bottom: 40px;
    }
    section.about-section .row.align-items-center{
        padding: 0px 15px;
    }
    section.about-section img.lefttimg-about{
        display: none;
    }
    .testimonial-section {
        padding-top: 0px;
        background-image: none!important;
    }
    section.testimonial-section .owl-theme .owl-nav{
        bottom: 0px;
        transform: unset;
        top: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        left: auto;
        right: auto;
        width: 100%;
        position: relative;
    }
  .ready-to-work h3 {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 10px;
    }
    section.hero-sec .item-hero-inner{
        animation: none;
    }
    .header nav.nav{
        display: none;
    }
}
@media (max-width: 767.98px) {
    .breadrum-tittle{
        padding-top: 100px;
    }
    section.about-quesolution  img.img-fluid{
        height: 500px;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }
    .about-owlslider {
        padding: 15px;
    }
    section.about-quesolution button.owl-next, section.about-quesolution button.owl-prev {
        width: 15px;
        height: 16px;
        font-size: 16px !important;
        text-align: right;
    }
    section.about-quesolution .owl-theme .owl-nav{
        right: 10px;
    }
    .bg-about-inner {
        height: 500px!important;

    }
    section.hero-sec h1 {
        font-size: 30px;
    }
    .bg-about-content-outer::before, .bg-about-content-outer::after{
        display: none;
    }
    .bg-about-content, .conatctuseform{
        padding: 15px;
    }
    section.about-section img.rightimg-about{
        display: none;
    }
    .form-check{
        margin-bottom: 15px;
    }
    .section-title img{
        margin-right: 0px;
    }
    footer.footer{
        background-size: cover;
    }
    .menu-footer ul li {
        margin: 0px 20px 0px 0px;
    }
    .menu-footer ul li a{
        padding: 10px 0px;
    }
    .copyright p, .privacy-links p{
        text-align: center;
    }
    .privacy-links{
        justify-content: center;
        margin-top: 15px;
    }
    section.about-innerpage{
        padding-bottom: 0px;
    }
    .contact-quesolution, .staticpage{
        padding-bottom: 50px;
    }
}
@media (max-width: 575.98px) {
    section.about-section button.tab-button, section.hero-sec ul li{
        font-size: 14px;
    }
    .product-box img{
        height: auto;
        width: 100% !important;
    }
    ul.pagination.justify-content-center li.page-item button.page-link{
        width: 40px;
        height: 40px;
        font-size: 18px;
    }
    ul.pagination.justify-content-center{
        justify-content: center!important;
    }
    section.hero-sec ul{
        gap: 15px;
    }
    .pxl-item--inner .content-bottom{
        flex-wrap: wrap;
    }
    .btn-more{
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .pxl-item--meta{
        margin-top: 10px;
    }
    section.about-section .tabs {
        display: flex;
        gap: 15px;
        overflow-x: auto; /* Allow horizontal scrolling */
        overflow-y: hidden; /* Prevent vertical scrolling */
        width: 100%; /* Ensure it spans full width */
        white-space: nowrap; /* Prevent line breaks to ensure items are in a single line */
        scrollbar-width: thin; /* For Firefox */
        -ms-overflow-style: none;  /* For Internet Explorer and Edge */
        scrollbar-color: #888 #f5f5f5; /* For Firefox */
    }
    
    section.about-section .tabs::-webkit-scrollbar {
        height: 8px; /* Height of the scrollbar */
    }
    
    section.about-section .tabs::-webkit-scrollbar-thumb {
        background-color: #888; /* Color of the scrollbar */
        border-radius: 10px; /* Rounded corners for the scrollbar */
    }
    .section-title h2, .ready-to-work h3, .breadrum-tittle h1, .sitemapaddpage h2 {
        font-size: 25px;
        line-height: 40px;
    }
    footer.footer{
        background-size: cover;
    }
    section.about-section .tabs::-webkit-scrollbar-track {
        background-color: #f5f5f5; /* Background of the scrollbar track */
    }
    section.hero-sec ul li img {
        margin-right: 5px;
        height: 20px;
    }
    .bg-about-inner {
        height: 350px !important;
    }
}


